import React from "react";
import Image from "gatsby-image";
import { graphql } from "gatsby";
import "../styles/single-offer.scss";
import Heading from "../components/Heading";
import Seo from "../components/seo";

export const query = graphql`
  query querySingleOffer($id: String) {
    datoCmsOffer(id: { eq: $id }) {
      title
      list
      list2
    }
    offer: file(name: { eq: "offer" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

const OfferLayout = ({ data }) => {
  return (
    <>
      <Seo
        title={data.datoCmsOffer.title}
        description={`Praca konin - ${data.datoCmsOffer.title}`}
        keywords={[
          "praca konin",
          "kierowca praca konin",
          "kierowca praca usługi transportowe",
        ]}
      />
      <div className="single-offer container">
        <Heading Tag="h2" text={data.datoCmsOffer.title} />
        <div className="content">
          <div className="desc">
            <div
              className="list"
              dangerouslySetInnerHTML={{ __html: data.datoCmsOffer.list }}
            ></div>
            <div
              className="list"
              dangerouslySetInnerHTML={{ __html: data.datoCmsOffer.list2 }}
            ></div>
          </div>
          <div className="image">
            <Image fluid={data.offer.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferLayout;

// export const query = graphql`
//   {
//     hero: file(name: { eq: "hero" }) {
//       childImageSharp {
//         fluid(maxWidth: 2000, quality: 100) {
//           ...GatsbyImageSharpFluid_noBase64
//         }
//       }
//     }
//   }
// `;
